import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layouts/layout'
import PostItem from '../components/posts/post-item'
import Metadata from '../components/common/metadata'

export default function PostTemplate({ data }) {
  const { site, post } = data

  return (
    <Layout>
      <Metadata
        title={`${post.title} — ${site.siteMetadata.title}`}
        description={`Clipped from: ${post.link}`}
        socialTitle={post.title}
      />

      <PostItem
        title={post.title}
        created={post.created}
        content={post.content}
        slug={post.slug}
        link={post.link}
      />
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    post(slug: {eq: $slug}) {
      title
      slug
      link
      type
      content
      created
    }
  }
`
